import { PropType } from 'vue';
import { Props } from '../../types/core';

// Model -------------------------------------------------------------------------------------------

export interface FooterNewsletterModel {
	uid: string;
	loading?: boolean;
}

// Props -------------------------------------------------------------------------------------------

export const FooterNewsletterProps: Props<FooterNewsletterModel> = {
	uid: {
		type: String as PropType<FooterNewsletterModel['uid']>,
		required: true,
	},
	loading: {
		type: Boolean as PropType<FooterNewsletterModel['loading']>,
		default: false,
	},
};
