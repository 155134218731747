import Vue from 'vue';
import Logger from '@/node_modules/@osp/utils/src/logger';
import { TabToggle, TabToggleTheme } from '../../types/tabs';
import { TabTogglesProps, TabTogglesModel } from './TabToggles.props';

// Component ---------------------------------------------------------------------------------------

export default Vue.extend({
	name: 'TabToggles',
	props: TabTogglesProps,
	data() {
		return {
			selectedIds: [] as string[],
		};
	},
	computed: {
		selectedItems(): TabToggle[] {
			return this.items.filter((el) => this.selectedIds.includes(el.id));
		},
		selectedItemsFormatted(): TabTogglesModel['value'] {
			const itemsFormatted = this.selectedItems.length ? this.selectedItems.map((el) => el.id) : [];

			return this.allowMultipleActiveItems ? itemsFormatted : itemsFormatted?.[0] || '';
		},
		isThemeLight() {
			return this.theme === TabToggleTheme.LIGHT;
		},
		isThemeDark() {
			return this.theme === TabToggleTheme.DARK;
		},
	},
	watch: {
		value: {
			immediate: true,
			handler() {
				this.selectItemsFromValue();
			},
		},
	},
	methods: {
		isItemSelected(item: TabToggle) {
			return this.selectedIds.includes(item.id);
		},
		selectItem(item: TabToggle) {
			if (this.isItemSelected(item)) return;

			this.selectedIds.push(item.id);
		},
		handleClick(item: TabToggle) {
			const isOnlyOneSelected = this.isItemSelected(item) && this.selectedItems.length === 1;
			const unSelectItem = ({ id }: TabToggle) => {
				this.selectedIds = this.selectedIds.filter((selectedId) => selectedId !== id);
			};

			if (this.isItemSelected(item)) {
				if ((!this.allowUnselectAll && !isOnlyOneSelected) || this.allowUnselectAll) {
					unSelectItem(item);
				}
			} else if (!this.isItemSelected(item)) {
				if (!this.allowMultipleActiveItems) {
					this.selectedIds = [item.id];
				} else {
					this.selectItem(item);
				}
			}

			this.$emit('select', { item, selected: this.isItemSelected(item) });
			// When allowMultipleActiveItems is true emitted value is of type String[]
			// when not it is a String. See computed 'selectedItemsFormatted'
			this.$emit('input', this.selectedItemsFormatted);
		},
		selectItemsFromValue() {
			if (!this.allowMultipleActiveItems && typeof this.value === 'string' && this.value.length) {
				const item = this.items.find((el) => el.id === this.value);

				if (item) {
					this.selectItem(item);
				}
			} else if (
				this.allowMultipleActiveItems &&
				typeof this.value === 'object' &&
				typeof this.value?.[0] === 'string'
			) {
				this.selectedIds = this.value || [];
			} else {
				if (!this.value) {
					this.selectedIds = [];

					return;
				}

				Logger.warn('Check if provided TabToggles value type is correct');
			}
		},
	},
});
