import { PropType } from 'vue';
import { Props } from '../../types/core';
import { TabToggle, TabToggleTheme } from '../../types/tabs';

// Model -------------------------------------------------------------------------------------------

export interface TabTogglesModel {
	uid: string;
	value: string | string[];
	items: TabToggle[];
	allowMultipleActiveItems?: boolean;
	allowUnselectAll?: boolean;
	theme?: TabToggleTheme;
}

// Props -------------------------------------------------------------------------------------------

export const TabTogglesProps: Props<TabTogglesModel> = {
	uid: {
		type: String as PropType<TabTogglesModel['uid']>,
		required: true,
	},
	value: {
		type: [String, Array] as PropType<TabTogglesModel['value']>,
		required: false,
	},
	items: {
		type: Array as PropType<TabTogglesModel['items']>,
		required: true,
	},
	allowMultipleActiveItems: {
		type: Boolean as PropType<TabTogglesModel['allowMultipleActiveItems']>,
		default: false,
	},
	allowUnselectAll: {
		type: Boolean as PropType<TabTogglesModel['allowUnselectAll']>,
		default: false,
	},
	theme: {
		type: String as PropType<TabTogglesModel['theme']>,
		default: TabToggleTheme.DARK,
	},
};
