import { render, staticRenderFns } from "./FooterNewsletter.vue?vue&type=template&id=22d2812e&scoped=true&"
import script from "./FooterNewsletter.ts?vue&type=script&lang=ts&"
export * from "./FooterNewsletter.ts?vue&type=script&lang=ts&"
import style0 from "./FooterNewsletter.scss?vue&type=style&index=0&id=22d2812e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../shop/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22d2812e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TabToggles: require('/opt/jenkins/workspace/ospbuild/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/TabToggles/TabToggles.vue').default,TextField: require('/opt/jenkins/workspace/ospbuild/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/TextField/TextField.vue').default,FormField: require('/opt/jenkins/workspace/ospbuild/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/FormField/FormField.vue').default,Trigger: require('/opt/jenkins/workspace/ospbuild/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/Trigger/Trigger.vue').default,Ico: require('/opt/jenkins/workspace/ospbuild/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/Ico/Ico.vue').default})
