import Vue from 'vue';
import { validationMixin } from 'vuelidate';
import { maxLength, required, email } from 'vuelidate/lib/validators';
import { FooterNewsletterProps } from './FooterNewsletter.props';

// Component ---------------------------------------------------------------------------------------

export default Vue.extend({
	name: 'FooterNewsletter',
	mixins: [validationMixin],
	props: FooterNewsletterProps,
	data: () => {
		return {
			gender: '',
			email: '',
		};
	},
	computed: {
		genderItems() {
			return [
				{ id: 'FEMALE', label: this.$t(this.$i18nKeys.footerNewsletter.form.gender.female) },
				{ id: 'MALE', label: this.$t(this.$i18nKeys.footerNewsletter.form.gender.male) },
			];
		},
	},
	validations: {
		gender: {
			required,
		},
		email: {
			required,
			email,
			maxLength: maxLength(100),
		},
	},
	methods: {
		clearFields() {
			this.gender = '';
			this.email = '';
			this.$v.$reset();
		},
		handleSubmit() {
			this.$v.$touch();
			if (this.$v.$invalid) return;
			this.$emit('submit', { email: this.email, gender: this.gender });
			this.$v.$reset();
		},
	},
});
