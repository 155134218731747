export interface TabToggle {
	id: string;
	label?: string;
}

export enum TabToggleTheme {
	LIGHT = 'light',
	DARK = 'dark',
}

export interface TabsItem {
	id: string;
	label: string;
	name?: string;
	data?: any;
}
